const steps = {
  "webLoanProcessing.creditLineIndia.screen.1": {
    component: () => import("@/components/app/screens/CalculatorScreen.vue"),
  },

  "WebIdentification.mobileNumber.screen.1": {
    component: () =>
      import("@/components/app/screens/IdentificationScreen.vue"),
  },

  "WebAuthentication.otpCheckMobile.screen.1": {
    component: () =>
      import(
        "@/components/app/screens/AuthenticationOtpCheckMobileFirstScreen.vue"
      ),
  },
  "WebAuthentication.otpMobileAttemptsNumberExhausted.screen.1": {
    component: () =>
      import(
        "@/components/app/screens/AuthenticationOtpCheckMobileSecondScreen.vue"
      ),
  },

  "WebAuthentication.thirdPartyOtpDataCorrectionAttempsExhausted.screen.1": {
    component: () =>
      import(
        "@/components/app/screens/AuthenticationThirdPartyOtpCheckSecondScreen.vue"
      ),
  },
  "WebAuthentication.thirdPartyOtpCheck.screen.1": {
    component: () =>
      import(
        "@/components/app/screens/AuthenticationThirdPartyOtpCheckFirstScreen.vue"
      ),
  },

  "WebForm1.personalData.screen.1": {
    component: () =>
      import("@/components/app/screens/PersonalDataFormScreen.vue"),
  },
  "WebForm1.residentialAddress.screen.1": {
    component: () => import("@/components/app/screens/AddressFormScreen.vue"),
  },
  "WebForm1.documents.screen.1": {
    component: () => import("@/components/app/screens/DocumentsFormScreen.vue"),
  },

  "WebQuestionnairePart2.screen.1": {
    component: () => import("@/components/app/screens/OccupationScreen.vue"),
  },

  "webForm2.employed.screen.1": {
    component: () =>
      import("@/components/app/screens/IncomeDetailsEmployedScreen.vue"),
  },
  "webForm2.employed.screen.2": {
    component: () => import("@/components/app/screens/OfficeAddressScreen.vue"),
  },

  "webForm2.selfEmployed.screen.1": {
    component: () =>
      import("@/components/app/screens/IncomeDetailsSelfEmployedScreen.vue"),
  },

  "webForm2.studentUnemployed.screen.1": {
    component: () =>
      import(
        "@/components/app/screens/IncomeDetailsStudentUnemployedScreen.vue"
      ),
  },
  
  // Based on webForm2.employed.screen.2 screen
  "webForm2.webEmployedWorkAddress.screen.1": {
    component: () =>
      import("@/components/app/screens/OfficeAddressSecondScreen.vue"),
  },

  "WebStatus.main.screen.1": {
    component: () => import("@/components/app/screens/StatusScreen.vue"),
  },

  "downloadApp.approvalAmountGreater.screen.1": {
    component: () =>
      import("@/components/app/screens/ApprovalAmountGreaterScreen.vue"),
  },
  "downloadApp.approvalAmountEqualOrLess.screen.1": {
    component: () =>
      import("@/components/app/screens/ApprovalAmountEqualOrLessScreen.vue"),
  },
  "downloadApp.approvalAmountEqual.screen.1": {
    component: () =>
      import("@/components/app/screens/ApprovalAmountEqualScreen.vue"),
  },
  "downloadApp.unsuccessfully.screen.1": {
    component: () =>
      import("@/components/app/screens/UnsuccessfullyScreen.vue"),
  },
  "downloadApp.userRegistered.screen.1": {
    component: () =>
      import("@/components/app/screens/UserRegisteredScreen.vue"),
  },

  // 1
  "webAadhaarPanVerification.aadhaarPANErrors.screen.1": {
    component: () =>
      import("@/components/app/screens/VerificationAadhaarPanErrorsScreen.vue"),
  },
  // 2
  "webAadhaarPanVerification.nameSurnameDOBErrors.screen.1": {
    component: () =>
      import(
        "@/components/app/screens/VerificationNameSurnameDobErrorsScreen.vue"
      ),
  },
  // 3
  "webAadhaarPanVerification.aadhaarPanwithPersonalDataErrors.screen.1": {
    component: () =>
      import(
        "@/components/app/screens/VerificationAadhaarPanWithPersonalDataErrorsScreen.vue"
      ),
  },
  // 4
  "webAadhaarPanVerification.aadhaarPanChangeIsBlocked.screen.1": {
    component: () =>
      import(
        "@/components/app/screens/VerificationAadhaarPanChangeIsBlockedScreen.vue"
      ),
  },
  // 5
  "webError.somethingWentWrong.screen.1": {
    component: () =>
      import("@/components/app/screens/SomethingWentWrongScreen.vue"),
  },
  // -> otp screen
};

export default steps;
