import StepsApi from "@/services/steps";
import {
  check4DigsOTPStatus,
  check6DigsOTPStatus,
  checkNeedToReRenderScreenTask,
  checkScreenAutoUpdate,
  disableScreenFetchingTask,
  enableNodeLoaderTask,
  enableScreenFetchingTask,
} from "./defaultTasksConstants";
import eventBus from "@/utils/eventBus";

const getNodeData = async (
  store,
  { destination, screenId, nodeData = {}, elementId, eventId }
) => {
  if (store.state.isNodeLoading) return;

  store.dispatch("runAllTasksBeforeTransition");

  // TODO: Мб стоит перенести логику при монтировании экрана в стор
  // Двойной лоадер мертв при рождении
  // Эти двое не будут добавляться в стек задач перехода (они выполняются перед получением ноды, стек же запускается когда нода уже есть)
  // @See currentStepMixin/mounted (set false on screen mounted)
  // store.dispatch("setIsNodeLoading", true);
  // store.dispatch("setIsFetchingScreen", true);
  try {
    const response = await StepsApi.fetchStep(
      {
        destination,
        screenId,
        nodeData,
        elementId,
        eventId,
      },
      { nodeWithTokens: true }
    );
    const { node } = response.data;

    // Инвалидный кейс если не сработал переход
    if (node?.screens[0]?.id === store.state.currentNode?.screens[0]?.id) {
      store.dispatch("setIsNodeLoading", false);
      store.dispatch("setNeedToReRenderScreen", true);
    }
    
    // Супер-инвалидный кейс, если на процессе будет 2 одинаковых экрана, но с разными айдишниками
    // if (node?.screens[0]?.name === store.state.currentNode?.screens[0]?.name) {
    //   store.dispatch("setIsNodeLoading", false);
    //   store.dispatch("setNeedToReRenderScreen", true);
    // }

    store.commit("setCurrentNode", node);
    await store.dispatch("screen/createScreenFromNode", { node });
  } catch (e) {
    console.error(e);
  } finally {
    store.dispatch("runAllTasksAfterTransition");
  }
};

const initializeNode = async ({ dispatch }) => {
  dispatch("registerTasks");

  await dispatch("getNodeData", {});
};

const transitionToNode = async (
  { dispatch, state, rootState },
  { destination, useBackgroundSync = false, elementId, eventId }
) => {
  const screenId = rootState.node.screen.currentScreen?.id;
  const nodeData = rootState.node.screen.currentScreenData;

  // Refresh action will always with true (must call inside setInterval), unlike other actions
  if (state.useBackgroundSync !== useBackgroundSync) {
    dispatch("setUseBackgroundSync", useBackgroundSync);
  }

  await dispatch("getNodeData", {
    destination,
    screenId,
    nodeData,
    elementId,
    eventId,
  });
};

const transitionToNextNode = async ({ dispatch }) => {
  await dispatch("transitionToNode", { destination: "next" });
};

const transitionToPrevNode = async ({ dispatch }) => {
  await dispatch("transitionToNode", { destination: "previous" });
};

// 50/50 useless method
const refreshCurrentNode = async ({ dispatch }) => {
  await dispatch("transitionToNode", { destination: "refresh" });
};

const setIsNodeLoading = ({ commit }, isLoading) => {
  commit("setIsNodeLoading", isLoading);
};

const setIsFetchingScreen = ({ commit }, isFetching) => {
  commit("setIsFetchingScreen", isFetching);
};

const setUseBackgroundSync = ({ commit }, isHidden) => {
  // console.log("setUseBackgroundSync", isHidden);
  commit("setUseBackgroundSync", isHidden);
};

const setNeedToReRenderScreen = ({ commit }, status) => {
  commit("setNeedToReRenderScreen", status);
};

const emitRerenderCurrentScreenEvent = ({ dispatch }) => {
  eventBus.$emit("rerender-current-screen");

  dispatch("setNeedToReRenderScreen", false);
};

const checkNeedToReRenderScreen = ({ state, dispatch }) => {
  if (state.needToReRenderScreen) {
    dispatch("emitRerenderCurrentScreenEvent");
  }
};

const registerTasks = ({ state }) => {
  // TODO: temp code for tests
  state.tasksBeforeTransition.unshift(enableScreenFetchingTask);
  state.tasksBeforeTransition.unshift(enableNodeLoaderTask);

  state.tasksAfterTransition.unshift(check4DigsOTPStatus);
  state.tasksAfterTransition.unshift(check6DigsOTPStatus);
  state.tasksAfterTransition.push(checkNeedToReRenderScreenTask);
  state.tasksAfterTransition.push(checkScreenAutoUpdate);

  state.tasksAfterTransition.push(disableScreenFetchingTask);
};

// Add to tasks list as LAST item
const addTaskBeforeTransition = ({ state, commit }, task) => {
  const currentTasks = state.tasksBeforeTransition;

  if (currentTasks.find((item) => item?.actionType === task?.actionType)) {
    throw new Error(`Task ${task?.actionType} already exist`);
  }

  commit("setTasksBeforeTransition", [...currentTasks, task]);
};

// Add to tasks list as LAST item
const addTaskAfterTransition = ({ state, commit }, task) => {
  const currentTasks = state.tasksAfterTransition;

  // TODO: Мб такой кейс всё же возможен
  // if (currentTasks.find((item) => item?.actionType === task?.actionType)) {
  //   throw new Error(`Task ${task?.actionType} already exist`);
  // }

  commit("setTasksAfterTransition", [...currentTasks, task]);
};

const runTaskHandler = ({ dispatch }, task) => {
  const { actionType, actionPayload, actionOptions } = task;

  if (!actionType) {
    throw new Error(`Unknown task: ${JSON.stringify(task)}`);
  }

  try {
    dispatch(actionType, actionPayload, actionOptions);
  } catch (e) {
    throw new Error(`Task ${actionType} processing error: ${e}`);
  }
};

const runAsyncTaskHandler = async ({ dispatch }, task) => {
  const { actionType, actionPayload, actionOptions } = task;

  if (!actionType) {
    throw new Error(`Unknown task: ${JSON.stringify(task)}`);
  }

  try {
    await dispatch(actionType, actionPayload, actionOptions);
  } catch (e) {
    throw new Error(`Async task ${actionType} processing error: ${e}`);
  }
};

// Clear non-permanent tasks only
const clearTasksBeforeTransition = ({ commit }) => {
  commit("clearTasksBeforeTransition");
};

// Clear non-permanent tasks only
const clearTasksAfterTransition = ({ commit }) => {
  commit("clearTasksAfterTransition");
};

// Private action
const runAllTasks = async ({ dispatch }, tasks) => {
  if (!tasks?.length) return;

  // JS moment. Array.forEach don't support promises, but "for" do :)
  for (let index = 0; index < tasks.length; index++) {
    const task = tasks[index];

    if (!task?.isAsync) dispatch("runTaskHandler", task);
    if (task?.isAsync) await dispatch("runAsyncTaskHandler", task);
  }
};

const runAllTasksBeforeTransition = async ({ state, dispatch }) => {
  const tasks = state.tasksBeforeTransition;

  dispatch("runAllTasks", tasks);
  dispatch("clearTasksBeforeTransition");
};

const runAllTasksAfterTransition = async ({ state, dispatch }) => {
  const tasks = state.tasksAfterTransition;

  dispatch("runAllTasks", tasks);
  dispatch("clearTasksAfterTransition");
};

export default {
  initializeNode,
  getNodeData,
  transitionToNode,
  transitionToNextNode,
  transitionToPrevNode,
  refreshCurrentNode,
  setIsNodeLoading,
  setIsFetchingScreen,
  setUseBackgroundSync,
  setNeedToReRenderScreen,
  emitRerenderCurrentScreenEvent,
  checkNeedToReRenderScreen,
  registerTasks,
  addTaskBeforeTransition,
  addTaskAfterTransition,
  runTaskHandler,
  runAsyncTaskHandler,
  runAllTasks,
  runAllTasksBeforeTransition,
  runAllTasksAfterTransition,
  clearTasksBeforeTransition,
  clearTasksAfterTransition,
};
